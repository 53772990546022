import React from 'react';
import {Typography} from "@mui/joy";
import {Map, Place} from "@mui/icons-material";
import {getReasonPair, getTerritoryTypeColor, getTerritoryTypeName, TerritoryType} from "../CityMap/iconUtils";
import {BetterDrawer} from "../BetterDrawer/BetterDrawer";

export const Legend = ({handleDrawerClose, open}) => {
    const content = <>
        {Object.values(TerritoryType).map((type, index) => (
            <div key={index}>
                <Typography startDecorator={<Place sx={{
                    color: getTerritoryTypeColor(type)
                }}/>}>
                    {getTerritoryTypeName(type)}
                </Typography>
            </div>
        ))}
        {[0,1,2,3,4,5,6,7,8,9,10].map((index) => (
            <div key={index}>
                <Typography>
                    Numer: {index + 1}: {getReasonPair(index).name}<br/>{getReasonPair(index).description}
                </Typography>
            </div>
        ))}
    </>

    return <BetterDrawer handleDrawerClose={handleDrawerClose}
                         open={open}
                         title={"Legenda"}
                         startDecorator={<Map sx={{ color: 'white' }} />}
                         content={content}/>
}