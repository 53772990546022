import React, {Fragment, useState} from 'react';
import {
    GeoJSON,
    MapContainer,
    Marker,
    Polygon,
    Polyline,
    TileLayer,
    Tooltip,
} from 'react-leaflet';
import 'leaflet-boundary-canvas';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {
    getTerritoryTypeKey,
    getIcon,
    getTerritoryTypeColor,
    getTerritoryTypeName,
    MapLayerType
} from "./iconUtils";
import {Stack, Typography} from "@mui/joy";
import {Place} from "@mui/icons-material";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

function MapLayer() {
    return <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    />
}

export const CityMap = ({locations, silesianVoivodeshipGeoJson, filters}) => {
    const mapElements = [];
    const [map, setMap] = useState(undefined);
    const [zoom, setZoom] = useState(15);


    const getStyle = (feature) => {
        const isTargetCity = feature.properties && feature.properties.name === locations.location_name;

        return {
            color: isTargetCity ? 'black' : 'transparent',
            weight: 2,
            fillOpacity: isTargetCity ? 0.1 : 0.6,
            fillColor: isTargetCity ? 'transparent' : 'black'
        };
    };

    const filterPoints = (geoJson) => {
        return {
            ...geoJson,
            features: geoJson.features.filter(feature => feature.geometry.type !== 'Point'),
        };
    };

    const getMapElements = () => {
        return mapElements.filter(element => filters.includes(element.type) && filters.includes(element.layer_type)).map((element, index) =>
            (<div key={index}>
                {(zoom > 13 || filters.includes(MapLayerType.WAYPOINT_ALWAYS_ACTIVE)) && element.marker}
                {element.area}
                {element.street}
                {element.point}
            </div>));
    }

    const generateShortLine = (point) => {
        const offset = 0.00002;
        return [
            [point[0] - offset, point[1] - offset],
            [point[0] + offset, point[1] + offset],
        ];
    };

    const drawMarkers = () => {
        for (let loc of locations.locations) {
            const territoryType = getTerritoryTypeKey(loc.type);
            const icon = getIcon(territoryType);
            const color = getTerritoryTypeColor(territoryType);

            const element = {};

            element.type = loc.type;

            element.is_point = loc.point;

            element.layer_type = loc.area ? MapLayerType.AREA : loc.point ? MapLayerType.WAYPOINT : MapLayerType.STREET;

            element.reasons = loc.reasons;

            element.area = loc.area ? <Polygon
                key={`polyline-${loc.type}`}
                positions={loc.positions}
                color={color}
                fillColor={color}
                fillOpacity={0.4}
            /> : undefined;

            element.street = !loc.area ? <Polyline
                key={`polyline-${loc.type}`}
                positions={loc.point ? generateShortLine(loc.positions[0]) : loc.positions}
                color={color}
                weight={5}
            /> : undefined;

            const p = [0, 0];
            loc.positions.forEach(pos => {
                p[0] += pos[0];
                p[1] += pos[1];
            });

            if (!loc.show_marker) {
                mapElements.push(element);
                continue;
            }

            const position = loc.custom_marker ? loc.custom_marker : [p[0] / loc.positions.length, p[1] / loc.positions.length];
            element.marker = <Marker
                key={`marker-${loc.positions.length}-${loc.type}`}
                position={position}
                icon={icon}
            >
                <Tooltip>
                    <div>
                        <Typography startDecorator={<Place sx={{ pr: 1.3, pt: 1.4, color: getTerritoryTypeColor(loc.type) }} />} fontWeight="bold" sx={{ color: getTerritoryTypeColor(loc.type) }}>
                            Teren {getTerritoryTypeName(loc.type)},
                        </Typography>
                        <Stack direction="row" spacing={0.3}>
                            <div>Powody:</div>
                            {loc.reasons.map((reason, index) => (<div key={index}>{reason}{index < loc.reasons.length - 1 && ', '}</div>))}
                        </Stack>
                        ul. {loc.street}
                        <br/>
                        {loc.comment}
                    </div>
                </Tooltip>
            </Marker>;

            mapElements.push(element);
        }

        return (
            <>
                {getMapElements()}
            </>
        );
    }

    return (
        <MapContainer
            zoomControl={false}
            center={locations.center}
            zoom={15}
            className="-z-10"
            style={{ height: "calc(100vh - 42px)", width: "100vw", zIndex: 0 }}
            maxBounds={locations.bounds}
            maxBoundsViscosity={1.0}
            maxZoom={16}
            minZoom={13}
            ref={(m) => {
                if (m == null) return;

                setMap(m);
                m.on('zoomstart', function (ev) {
                    setZoom(m.getZoom());
                })

                m.on('zoomend', function (ev) {
                    setZoom(m.getZoom());
                })
            }}
        >
            <MapLayer />
            <GeoJSON style={getStyle}
                     data={filterPoints(silesianVoivodeshipGeoJson).features} />
            {map && drawMarkers()}
        </MapContainer>
    );
}
