import React, {Fragment} from 'react';
import {BetterDrawer} from "../BetterDrawer/BetterDrawer";
import {InfoOutlined, Launch} from "@mui/icons-material";
import {Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Link, Typography} from "@mui/joy";

export const About = ({handleDrawerClose, open}) => {
    const content = <Fragment>
        <Typography color="secondary" fontWeight="bold">
            Mapa miasta Katowice. Badanie poczucia bezpieczeństwa
        </Typography>

        <Typography level="body-sm">
            Od 2023 roku w ramach Centrum Badawczego Polityki Publicznej i Problemów Regulacyjnych
            Uniwersytetu Śląskiego w Katowicach razem ze studentami prowadzimy badania dotyczące odbioru
            przestrzeni miejskiej. Badania te zakładają mapowanie przez uczestników badania (studentów)
            swoich wrażeń w czasie przebywania na ulicach miast województwa śląskiego. Pierwsze badania
            objęły swoim zasięgiem wszystkie dzielnice miasta Katowice. Badania są skoncentrowane na poczuciu
            bezpieczeństwa lub odczuwaniu zagrożenia.
            Projekt jest kierowany przez dr Annę Muś, prof. UŚ (Wydział Nauk Społecznych) oraz dr hab.
            Olgę Sitarz, prof. UŚ (Wydział Prawa i Administracji). Zebranie danych nie byłoby możliwe bez
            ogromnego wkładu pracy wykonanego przez naszych studentów (uczestników badań), którzy są
            równocześnie współtwórcami bazy danych, na której opiera się mapa. W tym miejscu chcemy im
            wszystkim bardzo serdecznie podziękować.
        </Typography>

        <AccordionGroup sx={{ marginLeft: -1 }}>
            <Accordion>
                <AccordionSummary><Typography marginX={-0.5} level="body-sm">Uczestnicy badań</Typography></AccordionSummary>
                <AccordionDetails>
                    Belowski Michał <br/>
                    Brachman Weronika <br/>
                    Celiński Kamil <br/>
                    Drąg Joanna <br/>
                    Gałka Weronika <br/>
                    Gibes Aleksandra <br/>
                    Golka Beniamin <br/>
                    Górska Julia <br/>
                    Janik Laura <br/>
                    Kania Maria <br/>
                    Kawczak Marta <br/>
                    Kirsek Jakub <br/>
                    Kłonica Natalia <br/>
                    Koc Aleksandra <br/>
                    Konwerska Agata <br/>
                    Korcz Zofia <br/>
                    Korzeń Jakub <br/>
                    Korzon Sylwia <br/>
                    Kubas Szymon <br/>
                    Kubiniec Julia <br/>
                    Kwiatkowska Maria <br/>
                    Lewandowski Andrzej <br/>
                    Lipska Julia <br/>
                    Łukoś Julia <br/>
                    Makówka Patrycja <br/>
                    Marek Aleksandra <br/>
                    Mateja Katarzyna <br/>
                    Mika Alicja <br/>
                    Mrówka Martyna <br/>
                    Pietraszko Karol <br/>
                    Polko Jakub <br/>
                    Puchała Oliwia <br/>

                    Rejman Bartosz <br/>
                    Roziński Maciej <br/>
                    Sarnik Wojciech <br/>
                    Sikora Gabriela <br/>
                    Skalmierski Kacper <br/>
                    Slamczyk Karolina <br/>
                    Sobala Julia <br/>
                    Sternicki Wojciech <br/>
                    Stępień Natalia <br/>
                    Strójwąs Kaja <br/>
                    Szopa Jan <br/>
                    Świech Anna <br/>
                    Trybus Zuzanna <br/>
                    Wiśniewska Natalia <br/>
                    Wołoszyn Michał <br/>
                    Zięba Małgorzata <br/>
                </AccordionDetails>
            </Accordion>
        </AccordionGroup>

        <Typography fontSize={12} fontWeight="lighter" level="body-xs">
            Źródło mapy:{' '}
            <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.openstreetmap.org/about"
                startDecorator={<Launch/>}>
                OpenStreetMap contributors
            </Link>
            <br/>
            Mapa wykonana we współpracy z:{' '}
            <Link
                target="_blank"
                rel="noopener noreferrer"
                startDecorator={<Launch/>}
                href="https://qsystems.pro/">
                QSystems.pro sp. z o.o.
            </Link>
        </Typography>
    </Fragment>

    return <BetterDrawer handleDrawerClose={handleDrawerClose}
                         open={open}
                         content={content}
                         startDecorator={<InfoOutlined sx={{ color: 'white' }}/>}
                         title={"O nas"} />
}