import './App.css';
import {CityMap} from "./components/CityMap/CityMap";
import {TopBar} from "./components/TopBar/TopBar";
import katolocation from './assets/data/locations.json'
import katowiceGeoJson from '../src/assets/data/slaskie.json';
import {useState} from "react";
import {
    getMapDefaultFilters,
} from "./components/CityMap/iconUtils";

function App() {
    const [filters, setFilters] = useState(getMapDefaultFilters());
    const [r, refresh] = useState(true);

    const setFiltersWithRefresh = (value) => {
        setFilters(value);
        refresh(!r);
    }

    return (
    <div className="App" style={{ overflow: "hidden" }}>
        <TopBar filters={filters} setFilters={setFiltersWithRefresh} />
        <CityMap locations={katolocation} silesianVoivodeshipGeoJson={katowiceGeoJson} filters={filters} />
    </div>
  );
}

export default App;
