import React, {Fragment, useEffect, useState} from 'react';
import {BetterDrawer} from "../BetterDrawer/BetterDrawer";
import {FilterAltOutlined} from "@mui/icons-material";
import {Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Checkbox, Stack, Typography} from "@mui/joy";
import {
    getMapLayerTypeName,
    getMapLayerTypeNames,
    getTerritoryTypeColor,
    getTerritoryTypeName,
    getTerritoryTypeNames, MapLayerType
} from "../CityMap/iconUtils";

export const Filters = ({ filters, setFilters, open, handleDrawerClose }) => {
    const content = <Fragment>
        <AccordionGroup sx={{ marginLeft: -1 }}>
            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography marginX={-0.5} level="body-md">Ogólne</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={1} fontWeight="light">
                        {getMapLayerTypeNames().map((element, index) => (
                            <Checkbox key={index} checked={filters.includes(element)}
                                      onChange={() => {
                                          let newFilters = filters;
                                          if(filters.includes(element)){
                                              const index = filters.indexOf(element);
                                              newFilters.splice(index, 1);
                                          } else {
                                              newFilters.push(element);
                                          }
                                          setFilters(newFilters);
                                      }}
                                      label={getMapLayerTypeName(element)} />
                        ))}
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography marginX={-0.5} level="body-md">Typy znaczników</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={1} fontWeight="light">
                        {getTerritoryTypeNames().map((element, index) => (
                            <Checkbox key={index} checked={filters.includes(element)}
                                      sx={{ color: getTerritoryTypeColor(element) }}
                                      onChange={() => {
                                          let newFilters = filters;
                                          if(filters.includes(element)){
                                              const index = filters.indexOf(element);
                                              newFilters.splice(index, 1);
                                          } else newFilters.push(element);
                                          setFilters(newFilters);
                                      }}
                                      label={getTerritoryTypeName(element)} />
                        ))}
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </AccordionGroup>
    </Fragment>

    return <BetterDrawer handleDrawerClose={handleDrawerClose}
                         content={content}
                         startDecorator={<FilterAltOutlined sx={{ color: 'white' }} />}
                         open={open}
                         title={"Filtry"}/>
}