import L from 'leaflet';
import defaultIcon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import yellowIcon from '../../assets/textures/marker-icon-yellow.png';
import greenIcon from '../../assets/textures/marker-icon-green.png';
import greyIcon from '../../assets/textures/marker-icon-grey.png';
import orangeIcon from '../../assets/textures/marker-icon-orange.png';
import redIcon from '../../assets/textures/marker-icon-red.png';

// Definiowanie typów terytoriów jako obiekt
export const TerritoryType = {
    SAFE: 'SAFE',
    NOTGOOD: 'NOTGOOD',
    RISKY: 'RISKY',
    UNSAFE: 'UNSAFE',
    TEMPUNSAFE: 'TEMPUNSAFE'
};

export const MapLayerType = {
    WAYPOINT: 'WAYPOINT',
    AREA: 'AREA',
    STREET: 'STREET',
    WAYPOINT_ALWAYS_ACTIVE: 'WAYPOINT_ALWAYS_ACTIVE'
};

export const getTerritoryTypeNames = () => {
    return Object.getOwnPropertyNames(TerritoryType);
}

export const getMapLayerTypeNames = () => {
    return Object.getOwnPropertyNames(MapLayerType);
}

export const getReasonPair = (index) => {
    switch (index) {
        case 0:
            return { name: 'Izolacja', description: 'Brak potencjalnych obserwatorów (świadków), przeszkody ograniczające widoczność\n' +
                    '(np. aleja zarośnięta po bokach krzakami)' };
        case 1:
            return { name: 'Bezludzie', description: 'Miejsce odludne, okolica w której nie ma ludzi.' };
        case 2:
            return { name: 'Wandalizm', description: 'Graffiti, śmieci, brud, zniszczone ławki, śmietniki, inne akty wandalizmu.' };
        case 3:
            return { name: 'Zaniedbanie', description: 'Zaniedbane budynki.' };
        case 4:
            return { name: 'Oświetlenie', description: 'Niedostateczne oświetlenie.' };
        case 5:
            return { name: 'Roślinność', description: 'Martwe, zaniedbanie rośliny.' };
        case 6:
            return { name: 'Osoby', description: 'Hałaśliwe zachowanie osób przebywających w danym miejscu, zaczepki słowne, osoby\n' +
                    'przebywające w danym miejscu budzą niepokój.' };
        case 7:
            return { name: 'Samochody', description: 'Intensywny ruch samochodowy, hałas z tym związany, spaliny, samochody\n' +
                    'parkujące utrudniają ruch pieszych.' };
        case 8:
            return { name: 'Inne', description: 'Dokładniej opisane w komentarzu danego miejsca.' };
        case 9:
            return { name: 'Brak chodników', description: 'Mniej miejsca na ruch pieszych.' };
        case 10:
            return { name: 'Roboty budowlane', description: 'Utrudnienia przez roboty budowlane.' };
        default:
            return { name: 'UNDEFINED', description: 'UNDEFINED' };
    }
}

export const getMapLayerTypeName = (mapLayerType) => {
    switch (mapLayerType) {
        case MapLayerType.AREA:
            return 'Pola';
        case MapLayerType.STREET:
            return 'Ulice';
        case MapLayerType.WAYPOINT:
            return 'Miejsca';
        case MapLayerType.WAYPOINT_ALWAYS_ACTIVE:
            return 'Zawsze pokazuj markery';
        default:
            return '';
    }
}

export const getMapFilters = () => {
    return getTerritoryTypeNames().concat(getMapLayerTypeNames());
}

export const getMapDefaultFilters = () => {
    const toRemove = [
        MapLayerType.WAYPOINT_ALWAYS_ACTIVE,
        TerritoryType.SAFE
    ]
    return getMapFilters().filter(e => !toRemove.includes(e));
}

export const getTerritoryTypeColor = (territoryType) => {
    switch (territoryType) {
        case TerritoryType.SAFE:
            return 'green';
        case TerritoryType.NOTGOOD:
            return 'gold';
        case TerritoryType.RISKY:
            return 'orange';
        case TerritoryType.UNSAFE:
            return 'red';
        default:
            return 'blue';
    }
}

export const getTerritoryTypeName = (territoryType) => {
    switch (territoryType) {
        case TerritoryType.SAFE:
            return 'Bezpieczny';
        case TerritoryType.NOTGOOD:
            return 'Nieprzyjemny';
        case TerritoryType.RISKY:
            return 'Ryzykowny';
        case TerritoryType.UNSAFE:
            return 'Niebezpieczny';
        case TerritoryType.TEMPUNSAFE:
            return 'Czasowo Niebezpieczny';
        default:
            return '';
    }
}
export const getTerritoryTypeKey = (value) => {
    let ret = "SAFE";
    Object.values(TerritoryType).forEach(v =>{
        if(v.toString() === value) ret = value;
    })
    return ret;
};

export const getIcon = (territoryType) => {
    let icon = defaultIcon;

    switch (territoryType) {
        case TerritoryType.SAFE:
            icon = greenIcon;
            break;
        case TerritoryType.NOTGOOD:
            icon = yellowIcon;
            break;
        case TerritoryType.RISKY:
            icon = orangeIcon;
            break;
        case TerritoryType.UNSAFE:
            icon = redIcon;
            break;
        default:
            icon = defaultIcon;
    }

    return new L.Icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });
}
