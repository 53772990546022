import { extendTheme } from '@mui/joy/styles';


declare module '@mui/joy/styles' {
    // No custom tokens found, you can skip the theme augmentation.
}


const theme = extendTheme({
    "colorSchemes": {
        "light": {
            "palette": {
                "primary": {
                    "50": "#fafaf9",
                    "100": "#f5f5f4",
                    "200": "#e7e5e4",
                    "300": "#d6d3d1",
                    "400": "#a8a29e",
                    "500": "#78716c",
                    "600": "#57534e",
                    "700": "#44403c",
                    "800": "#292524",
                    "900": "#1c1917"
                }
            }
        },
        "dark": {
            "palette": {
                "primary": {
                    "50": "#fafaf9",
                    "100": "#f5f5f4",
                    "200": "#e7e5e4",
                    "300": "#d6d3d1",
                    "400": "#a8a29e",
                    "500": "#78716c",
                    "600": "#57534e",
                    "700": "#44403c",
                    "800": "#292524",
                    "900": "#1c1917"
                }
            }
        }
    },
    "mode": "light"
})

export default theme;