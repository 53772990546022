import React from 'react';
import {
    ButtonGroup,
    IconButton,
    Stack,
    Typography
} from "@mui/joy";
import {
    FilterAltOutlined,
    InfoOutlined,
    Map,
    MapOutlined,
} from "@mui/icons-material";
import {Legend} from "../Legend/Legend";
import {About} from "../About/About";
import {Filters} from "../Filters/Filters";

export const TopBar = ({filters, setFilters}) => {
    const [legendOpen, setLegendOpen] = React.useState(true);
    const [aboutOpen, setAboutOpen] = React.useState(false);
    const [filtersOpen, setFiltersOpen] = React.useState(false);

    const handleLegendDrawerOpen = () => setLegendOpen(true);
    const handleLegendDrawerClose = () => setLegendOpen(false);

    const handleAboutDrawerOpen = () => setAboutOpen(true);
    const handleAboutDrawerClose = () => setAboutOpen(false);

    const handleFiltersDrawerOpen = () => setFiltersOpen(true);
    const handleFiltersDrawerClose = () => setFiltersOpen(false);

    const handleClick = (action, index) => {
        action();
        if (index !== 0) handleLegendDrawerClose()
        if (index !== 1) handleAboutDrawerClose()
        if (index !== 2) handleFiltersDrawerClose()
    }

    return <Stack
            sx={theme => ({
                background: `${theme.colorSchemes.light.palette.primary.plainColor}`
            })}
            direction="row-reverse"
            justifyContent="space-between"
            alignItems="center"
            paddingY={0}
            zIndex={10}
            margin={0.1}
            top={0}
            maxWidth="100%"
            height="40px"
            overflow="hidden"
        >
        <ButtonGroup sx={{ borderRadius: 0, height: '100%' }} color="primary" variant="solid">
            <IconButton onClick={() => handleClick(handleFiltersDrawerOpen, 2)} sx={{ width: '45px' }} >
                <FilterAltOutlined />
            </IconButton>
            <IconButton onClick={() => handleClick(handleLegendDrawerOpen, 0)} sx={{ width: '45px' }} >
                <MapOutlined />
            </IconButton>
            <IconButton onClick={() => handleClick(handleAboutDrawerOpen, 1)} sx={{ width: '45px' }} >
                <InfoOutlined />
            </IconButton>
        </ButtonGroup>
        <Typography startDecorator={<Map sx={{ color: 'white' }} />}
                    marginLeft={1}
                    fontWeight="bold"
                    fontSize={20} sx={{color: 'white'}}>
                Bezpieczeństwo w Mieście
        </Typography>
        <Legend handleDrawerClose={handleLegendDrawerClose} open={legendOpen}/>
        <About handleDrawerClose={handleAboutDrawerClose} open={aboutOpen} />
        <Filters handleDrawerClose={handleFiltersDrawerClose}
                 open={filtersOpen}
                 filters={filters}
                 setFilters={setFilters} />
    </Stack>
}