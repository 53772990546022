import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {CssVarsProvider} from "@mui/joy";
import theme from '../src/components/shared/theme/theme'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CssVarsProvider theme={theme} defaultMode="light">
        <App />
    </CssVarsProvider>
  </React.StrictMode>
);
