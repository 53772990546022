import React from 'react';
import {DialogContent, DialogTitle, Divider, IconButton, Sheet, Stack, Typography} from "@mui/joy";
import {CloseOutlined, Map, Place} from "@mui/icons-material";
import MuiDrawer from "@mui/joy/Drawer";

export const BetterDrawer = ({handleDrawerClose, open, content, title, startDecorator = undefined}) => {
    return <MuiDrawer
        size="sm"
        variant="plain"
        sx={{ width: '100px', zIndex: 0 }}
        open={open}
        hideBackdrop
        onClose={handleDrawerClose}
        anchor="right"
        slotProps={{
            content: {
                sx: {
                    bgcolor: 'transparent',
                    p: { md: 3, sm: 0 },
                    boxShadow: 'none',
                    zIndex: '0 !important',
                },
            },
        }}
    >
        <Sheet
            sx={{
                top: '25px',
                right: '-10px',
                borderRadius: 'md',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                height: 'calc(100% - 35px)',
                overflow: 'hidden'
            }}
        >
            <DialogTitle sx={theme => (
                { background: `${theme.colorSchemes.light.palette.primary.plainColor}`,
                    marginLeft: -1.8,
                    marginRight: -1.9,
                    marginTop: -1.9,
                    marginBottom: -1.9,
                    padding: 2,
                    borderRadius: '8px 8px 0 0',
                    paddingBottom: 2}
            )}>
                <Stack direction="row-reverse"
                       width="100%"
                       justifyContent="space-between"
                       alignItems="center">
                    <IconButton onClick={handleDrawerClose} sx={theme => (
                        {
                            '&:hover .MuiSvgIcon-root': {
                                background: `transparent`,
                                color: `${theme.colorSchemes.light.palette.primary.outlinedDisabledColor}`, // Kolor ikony po najechaniu
                            },
                        }
                    )}>
                        <CloseOutlined sx={{ color: 'white' }} />
                    </IconButton>
                    <Typography startDecorator={startDecorator}
                                fontWeight="bold"
                                fontSize={20}
                                sx={{ color: 'white' }}>
                        {title}
                    </Typography>
                </Stack>
            </DialogTitle>
            <Divider sx={{ mt: 'auto' }} />
            <DialogContent sx={{ gap: 2 }}>
                {content}
            </DialogContent>
        </Sheet>
    </MuiDrawer>
}